import * as React from "react"
import Footer from "./footer"
import Header from "./header"
import { graphql, useStaticQuery } from "gatsby"

const Layout = ({ location, children, search }) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulWeb {
          nodes {
            logo {
              gatsbyImageData(formats: NO_CHANGE, height: 75, quality: 100)
              title
            }
            info {
              address
              tax_id
              phone
              mail
            }
          }
        }
      }
    `
  )
  const info = data.allContentfulWeb.nodes[0].info

  return (
    <>
      <div id="fb-root" />
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v10.0"
        nonce="IXicEAOa"
      />
      <div className="page-wrapper">
        <main className={"main"}>
          <Header
            location={location}
            phone={info.phone}
            logo={data.allContentfulWeb.nodes[0].logo}
            search={search}
          />
          {children}
          <Footer info={info} />
        </main>
      </div>
    </>
  )
}

export default Layout

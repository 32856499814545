import React from "react"
import { Link } from "gatsby"
import { Container, Nav, Navbar } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Header = ({ location, phone, logo, search }) => {
  return (
    <header className="header mb-lg-1">
      <div className="header-top">
        <div className="container">
          <div className="header-right pr-sm-3 pr-md-5">
            <a href={`tel:${phone}`}><i className="icon-phone" />Call: {phone}</a>
          </div>
        </div>
      </div>
      <Container>
        <Navbar collapseOnSelect expand={"lg"} bg={"white"} variant={"light"} className={"w-100 p-0"}>
          <Navbar.Brand href="/">
            <GatsbyImage image={getImage(logo)} alt="Ngo Huong Garment Logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={"responsive-navbar-nav"} />
          <Navbar.Collapse id={"responsive-navbar-nav"}>
            <ul className="menu sf-arrows m-0">
              <Nav>
                <li className={`pt-1 pb-1 ${location.pathname === "/products" ? "active" : ""}`}>
                  <Link href={"/products"} className="sf-with-ul">Sản phẩm</Link>
                </li>
                <li className={`pt-1 pb-1 ${location.pathname === "/about" ? "active" : ""}`}>
                  <Link href={"/about"} className="sf-with-ul">Giới thiệu</Link>
                </li>
                <li className={`pt-1 pb-1 ${location.pathname === "/contact" ? "active" : ""}`}>
                  <Link href={"/contact"} className="sf-with-ul">Liên hệ</Link>
                </li>
              </Nav>
            </ul>
            <div className="header-right">
              <div className="w-100 header-search header-search-extended header-search-visible">
                <form action="/products/" method="get">
                  <div className="header-search-wrapper search-wrapper-wide" style={{ margin: "0 auto" }}>
                    <label htmlFor="q" className="sr-only">Search</label>
                    <input type="search" className="form-control" name="q" id="q"
                           placeholder="Tìm kiếm sản phẩm ..." defaultValue={search} />
                    <button className="btn btn-primary" type="submit"><i
                      className="icon-search" /></button>
                  </div>
                </form>
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}
export default Header
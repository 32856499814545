import React from "react"
import { Link } from "gatsby"
import "./footer.css"

const Footer = ({ info }) => {
  return (
    <footer className="footer footer-dark">
      <div className="footer-middle">
        <div className="container">
          <div className="row">

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Đến trang</h4>

                <ul className="widget-list">
                  <li><Link to={"/products"}>Sản phẩm</Link></li>
                  <li><Link to={"/about"}>Giới thiệu</Link></li>
                  <li><Link to={"/contact"}>Liên hệ</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="widget">
                <h4 className="widget-title">Liên hệ</h4>

                <ul className="widget-list">
                  <li><a href={`tel:${info.phone}`}>{info.phone}</a></li>
                  <li><a href={`mailto:${info.mail}`}>{info.mail}</a></li>
                  <li>{info.address}</li>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-lg-5">
              <div className="widget">
                <h4 className="widget-title">Kết nối với chúng tôi</h4>
                <div className="fb-page"
                     data-href="https://www.facebook.com/May-M&#x1eb7;c-Ng&#xf4;-H&#x1b0;&#x1edd;ng-111256920469597/"
                     data-width="300" data-height="500"
                     data-hide-cover="false"
                     data-show-facepile="true">
                  <blockquote
                    cite="https://www.facebook.com/May-M&#x1eb7;c-Ng&#xf4;-H&#x1b0;&#x1edd;ng-111256920469597/"
                    className="fb-xfbml-parse-ignore"><a
                    href="https://www.facebook.com/May-M&#x1eb7;c-Ng&#xf4;-H&#x1b0;&#x1edd;ng-111256920469597/">May
                    Mặc Ngô Hường</a>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p className="footer-copyright">Copyright © {new Date().getFullYear()} Ngo Huong. All Rights
            Reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer